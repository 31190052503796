const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },{
    path: ["/subscribe"],
    exact: true,
    component: "Subscribe",
  },{
    path: ["/wr"],
    exact: true,
    component: "WritingEvaluation",
  },
];

export default routes;
